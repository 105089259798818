import React from "react";
import "./footer.css";
import FLogo from "../../images/FLogo.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

const index = () => {
    const location = useLocation();
    return (
        <div className="footer-wrapper">
            <div className="container">
                <div className="footer-main">
                    {/* logo box */}
                    <div className="block_1">
                        <div className="logo-wrp">
                            <Link to="/">
                                <img src={FLogo} alt="footerLogo" className="img-fluid" />
                            </Link>
                        </div>
                        <div className="description-text">
                            <p>Realize the vision of Autonomous Enterprise.</p>
                        </div>
                    </div>
                    {/*  menu box */}
                    <div className="block_2">
                        <div className="menubox">
                            <div className="item-head reguler-text-white-f">
                                <h6>Company</h6>
                            </div>
                            <ul>
                                <li><Link className={`${location.pathname === '/about' ? 'active' : ''}`} to="#">About Us</Link></li>
                                <li><Link className={`${location.pathname === '/services' ? 'active' : ''}`} to="/services">Services</Link></li>
                                <li><Link className="menu-text" to="/">Blog</Link></li>
                            </ul>
                        </div>
                        <div className="menubox">
                            <div className="item-head reguler-text-white-f">
                                <h6>Useful Links</h6>
                            </div>
                            <ul>
                                <li><Link className="menu-text" to="/">Investor Information</Link></li>
                                <li><Link className="menu-text" to="/">Terms of Services</Link></li>
                                <li><Link className="menu-text" to="/">Privacy Policy</Link></li>
                                <li><Link className="menu-text" to="/">Documentation</Link></li>
                            </ul>
                        </div>
                    </div>
                    {/* input box */}
                    <div className="block_3">
                        <div className="reguler-text-white-f">
                            <h6 className="mb-0">NewsLetter</h6>
                        </div>
                        <p className="subtext-white">
                            Sign up and receive the latest updates via email.
                        </p>
                        <div className="input-Mail">
                            <input type="email" placeholder="Email" />
                        </div>
                        <div className="footer-btn">
                            <button className="f-btn">Subscribe</button>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <p>
                        © 2023 Actionabl <span className="mx-2">|</span> All rights reserved
                    </p>
                </div>
            </div>
        </div>
    );
};

export default index;
