import React, { useEffect, useState } from 'react';
import './contact.css';
import Gridimg from "../../../images/Gridimg.png";
import ReusedButton from '../../Button/ReusedButton';
import { Col, Modal, Row } from 'react-bootstrap';
import msg from "../../../images/msg-icon.svg";
import calender from "../../../images/calender-icon.svg";
const HomeContact = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        console.log("Modal should show now");
        setShow(true);
    };
    useEffect(() => {
        (function (h, b, s, n, i, p, e, t) {
            h._HB_ = h._HB_ || {};
            h._HB_.pid = i;
            t = b.createElement(s);
            t.type = "text/javascript";
            t.async = !0;
            t.src = n;
            e = b.getElementsByTagName(s)[0];
            e.parentNode.insertBefore(t, e);
        })(
            window,
            document,
            "script",
            "https://widget.honeybook.com/assets_users_production/websiteplacements/placement-controller.min.js",
            "65c7b491aa0292000841264b"
        );
    }, []);
    return (
        <>
            <div className="contact-wrapper">
                <div className="container">
                    <Row className="ctc-wrp">
                        <Col sm={6} className="description">
                            <div className="d-wrapper">
                                <div className="head-h3">
                                    <h3>Ready to explore the <br />
                                        <span> full potential</span> of business process automation and transform your operations? </h3>
                                </div>
                                <div className="reguler-text">
                                    Contact us today for a free demo and experience the Actionabl difference firsthand.
                                </div>
                                <div onClick={handleShow}>
                                    <ReusedButton text={'Get in Touch'} className='btn' />
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className="ImgBox">
                            <img src={Gridimg} alt='Gridimg' className='img-fluid' />
                        </Col>
                    </Row>
                </div>
            </div>
            {/* modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-icons">
                        <ul>
                            <li>
                                <img src={msg} alt="msg" />
                            </li>
                            <li>
                                <img src={calender} alt="calender" />
                            </li>{" "}
                        </ul>
                    </div>
                    <div className="title-h2 text-center">
                        <h2>
                            <span>Let’s </span> Talk
                        </h2>{" "}
                    </div>
                    <div className="contact-from"></div>

                    <div className="hb-p-65c7b491aa0292000841264b-1"></div>
                    <img
                        height="1"
                        width="1"
                        src="https://www.honeybook.com/p.png?pid=65c7b491aa0292000841264b"
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default HomeContact;
