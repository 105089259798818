import a1 from "../images/a1.jpg";
import a2 from "../images/a2.jpg";
import a3 from "../images/a3.jpg";
import a4 from "../images/a4.jpg";
import a5 from "../images/a5.jpg";
import a6 from "../images/a6.jpg";

import s1 from "../images/s1.png";
import s2 from "../images/s2.png";
import s3 from "../images/s3.png";

export const teamMembers = [
    {
        img: a1,
        firstName: "Chiman",
        lastName: "Jagani",
        text: "As an IIT Bombay alumnus, Chiman brings over 35 years of extensive IT experience to his role as Technology & Innovation Lead. He has delivered large-scale enterprise solutions for clients across North America, Europe, and Asia. A visionary and technology innovator, Chiman possesses a profound understanding of the past and a forward-thinking perspective for the future, making him a valuable asset in driving growth and innovation.",
    },
    {
        img: a2,
        firstName: "Chetan ",
        lastName: "Panchani",
        text: "With a background at McKinsey & Company and over 20 years of experience in digital transformation, business process management, and process automation, Chetan is a seasoned expert in driving change. He has led major transformations across North America, empowering large organizations to transform and innovate rapidly.",
    },
    {
        img: a3,
        firstName: "Sarvesh",
        lastName: "Renghe",
        text: "Sarvesh is a seasoned professional with over 30 years of experience in all aspects of software development. As a versatile Delivery Lead, He spearheads customer engagements and implementations, ensuring seamless delivery and customer satisfaction. Additionally, he manages partnerships and focuses on skill development, fostering a collaborative and growth-oriented environment for both our team and clients.",
    },
    {
        img: a4,
        firstName: "Keval",
        lastName: "Juthani",
        text: "With over a decade of experience in software development and technical architecture, Keval has honed his skills in guiding designers and engineers through design concepts, best practices, and implementation-related challenges. His expertise and leadership ensure that projects are executed efficiently and effectively, while staying aligned with the latest product standards.",
    },
    {
        img: a5,
        firstName: "Pradip",
        lastName: "Sharma",
        text: "Pradip leads Actionabl's global sales with a focus on delivering customer impact and satisfaction. Boasting 30 years of experience, Pradip excels in forging strategic partnerships and fostering growth by helping clients excel using our products and services. His visionary leadership and dedication to customer success cultivate strong, enduring relationships.",
    },
    {
        img: a6,
        firstName: "Vipin",
        lastName: "Panchamia",
        text: "Vipin is an seasoned finance and taxation expert with over three decades in the field. As a Finance Head, he looks after strategic planning and financial management, ensuring efficiency and profitability. His strategic insight and analysis are key in navigating today's financial challenges.",
    },
];

export const service = [
    {
        img: s1,
        firstName: "Automation",
        lastName: "as a Service",
        text: "Our automation solution is offered as a service, enabling you to pay only for the work our solution performs for your business. This allows you to avoid any upfront investments, reduce risks, and minimize operational overheads, making it easier and more cost-effective to integrate automation into your operations.",
    },
    {
        img: s2,
        firstName: "Business ",
        lastName: "Solution",
        text: "Are you seeking a complete automation solution at a single, all-inclusive price? Our solution is tailored to help you control costs, reduce risk, and eliminate implementation hassles. With our comprehensive automation solution, you can streamline your processes and focus on what matters most: growing your business.",
    },
    {
        img: s3,
        firstName: "Expert",
        lastName: "Services",
        text: "We provide expert services to assist with your process transformation and automation journey. Our team has extensive technical expertise and can help you achieve your goals using your preferred tools or ours. Let us help you enhance your productivity and efficiency to take your business to the next level.",
    },
];