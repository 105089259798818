import React from 'react';
import ServicesBanner from '../Components/Services/ServicesBanner';
import NavbarNav from '../Components/Header/NavbarNav';

const Service = () => {
    return (
        <>
            <NavbarNav />
            <ServicesBanner />
        </>
    );
};

export default Service;