import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './hero.css';
const Banner = () => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div id='home' className="hero-wrapper">
        <div className="container">
          <div className="content">
            <div className="hero-h1">
              <h1>Shaping the Future of</h1>
            </div>
            <div className="color-head">
              <h3>Autonomous Enterprise.</h3>
            </div>
            <div className="reguler-text">
              <p>Elevate your Business to New Heights of Efficiency and Innovation.</p>
            </div>
            <div className="hero-btn-group">
              {/* <button className="btn btn-1"> */}
              <Link className="btn btn-1" to='https://actionabl.hbportal.co/schedule/65c7b4913b055d001f4c0bec' target="_blank" rel="noopener noreferrer">
                Explore
              </Link>
              {/* </button> */}
              {/* <button className="btn-2"> */}
              <Link className="btn-2" to='https://actionabl.hbportal.co/schedule/65c7d7b69de8660025d65ed7' target="_blank" rel="noopener noreferrer">
                Schedule Demo
              </Link>
              {/* </button> */}
            </div>
          </div>
        </div>
      </div>
      <button id="button" className={`scroll-to-top-button ${showButton ? 'show' : ''}`} onClick={scrollToTop}></button>
    </>
  );
};

export default Banner;
