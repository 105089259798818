import React from 'react';
import './difference.css';
import d1 from "../../../images/d1.jpg";
import d2 from "../../../images/d2.jpg";
import d3 from "../../../images/d3.jpg";
import d4 from "../../../images/d4.jpg";
import RD1 from "../../../images/RD1.jpg";
import RD2 from "../../../images/RD2.jpg";
import RD3 from "../../../images/RD3.jpg";
import RD4 from "../../../images/RD4.jpg";
const index = () => {
    return (
        <div className="difference-wrapper">
            <div className="container">
                <div className="title-h2 text-center">
                    <h2>
                        <span>Actionabl </span> Difference
                    </h2>
                </div>
                <div className="diff-subtitle">
                    <p>Empowering Creativity and Uniqueness in the Age of AI Assisted Innovation</p>
                </div>
                {/* difference box */}
                <div className="difference-Box">
                    {/* difference items */}
                    <div className="difference-item">
                        <div className="D-image-box">
                            <img src={d1} alt='difference1' className='img-fluid  d-none d-md-block' />
                            <img src={RD1} alt='difference1' className='img-fluid  d-block d-md-none' />
                        </div>
                        <div className="item-box">
                            <div className="content-box">
                                <div className="diff-head">
                                    <h3>User-Centric</h3>
                                </div>
                                <div className="diff-text">Actionabl delivers a cohesive, user-friendly business solution, emphasizing transparency, interactivity, and actionability to enhance user experience.</div>
                            </div>
                        </div>
                    </div>
                    {/* item 2 */}
                    <div className="difference-item">
                        <div className="D-image-box">
                            <img src={d2} alt='difference2' className='img-fluid  d-none d-md-block' />
                            <img src={RD2} alt='difference2' className='img-fluid  d-block d-md-none' />
                        </div>
                        <div className="item-box">
                            <div className="content-box">
                                <div className="diff-head">
                                    <h3>Autonomous</h3>
                                </div>
                                <div className="diff-text">Designed specifically for autonomous business processes, Actionabl enables straight-through processing with essential human oversight, ensuring a harmonious blend of automation and human intervention.</div>
                            </div>
                        </div>
                    </div>
                    {/* item 3 */}
                    <div className="difference-item">
                        <div className="D-image-box">
                            <img src={d3} alt='difference3' className='img-fluid  d-none d-md-block' />
                            <img src={RD3} alt='difference3' className='img-fluid  d-block d-md-none' />
                        </div>
                        <div className="item-box">
                            <div className="content-box">
                                <div className="diff-head">
                                    <h3>Open & Extendable</h3>
                                </div>
                                <div className="diff-text">Featuring an open architecture and extendibility, including source code licensing options, Actionabl supports customization and expansion of its core capabilities to align with unique business needs.</div>
                            </div>
                        </div>
                    </div>
                    {/* item 4 */}
                    <div className="difference-item">
                        <div className="D-image-box">
                            <img src={d4} alt='difference4' className='img-fluid  d-none d-md-block' />
                            <img src={RD4} alt='difference4' className='img-fluid  d-block d-md-none' />
                        </div>
                        <div className="item-box">
                            <div className="content-box">
                                <div className="diff-head">
                                    <h3>Innovation Partner</h3>
                                </div>
                                <div className="diff-text">At its core, Actionabl is driven by innovation, keeping you at the forefront of technological advancements and forging partnerships to meet your specific needs.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default index;
