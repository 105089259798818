import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from '../src/Components/Footer/index';
import Homepage from './pages/Homepage';
import About from './pages/About';
import Service from './pages/Service';
function App() {
  return (
    <Router>
      {/* <NavbarNav /> */}
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/about" element={<Homepage /> } />
        <Route exact path="/aboutus" element={<Homepage /> } />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/services" element={<Service />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
