import React from 'react';
import './reUsedButton.css';
const ReusedButton = ({ text, className }) => {
    return (
        <button className={` ${className} withOutBg`}>
            {text}
        </button >
    );
};

export default ReusedButton;
