import React from 'react';
import './capability.css';
import card1 from "../../../images/card1.svg";
import card3 from "../../../images/card3.svg";
import card4 from "../../../images/card4.svg";
import card5 from "../../../images/card5.svg";
import card6 from "../../../images/card6.svg";
import card8 from "../../../images/card8.svg";
import card9 from "../../../images/card9.svg";
const index = () => {
    return (
        <div className="capability-wrapper">
            <div className="container">
                <div className="title-h2 text-center">
                    <h2><span>Core</span> Capabilities</h2>
                </div>
                <div className="Card-Box">
                    <div className="card-main">
                        <div className="card-Img">
                            <img className='img-fluid' src={card1} alt='card-img-1' />
                        </div>
                        <div className="card-head-text">
                            <h5>No-Code Apps</h5>
                        </div>
                        <div className="reguler-text">
                            Automate routine tasks like email management, file handling, document generation, web service integration, and more.
                        </div>
                    </div>
                    {/* colored card */}
                    <div className="card-main black-bg">
                        <div className="card-Img hide-content">
                            <img className='img-fluid hide-content' src={card1} alt='card-img-1' />
                        </div>
                        <div className="card-head-text hide-content">
                            <h5>No-Code Apps</h5>
                        </div>
                        <div className="reguler-text hide-content">
                            Automate routine tasks like email management, file handling, document generation, web service integration, and more.
                        </div>
                    </div>
                    <div className="card-main">
                        <div className="card-Img">
                            <img className='img-fluid' src={card5} alt='card-img' />
                        </div>
                        <div className="card-head-text">
                            <h5>No-Code Ui Designer</h5>
                        </div>
                        <div className="reguler-text">
                            Tailor user intervention and error recovery activities with our intuitive UI designer.
                        </div>
                    </div>
                    <div className="card-main">
                        <div className="card-Img">
                            <img className='img-fluid' src={card4} alt='card-img-4' />
                        </div>
                        <div className="card-head-text">
                            <h5>Robotic Process Automation</h5>
                        </div>
                        <div className="reguler-text">
                            Use RPA to automate web and desktop applications using its user interface.
                        </div>
                    </div>
                    <div className="card-main">
                        <div className="card-Img">
                            <img className='img-fluid' src={card3} alt='card-img-3' />
                        </div>
                        <div className="card-head-text">
                            <h5>AI Integration</h5>
                        </div>
                        <div className="reguler-text">
                            Incorporate advanced AI models to enhance decision-making, gain predictive insights, and access generative AI.
                        </div>
                    </div>
                    <div className="card-main">
                        <div className="card-Img">
                            <img className='img-fluid' src={card6} alt='card-img-6' />
                        </div>
                        <div className="card-head-text">
                            <h5>Optical Character Recognition</h5>
                        </div>
                        <div className="reguler-text">
                            Use OCR to parse documents and extract valuable data quickly.
                        </div>
                    </div>
                    {/* colored card */}
                    <div className="card-main black-bg">
                        <div className="card-Img hide-content">
                            <img className='img-fluid hide-content' src={card1} alt='card-img-1' />
                        </div>
                        <div className="card-head-text hide-content">
                            <h5>No-Code Apps</h5>
                        </div>
                        <div className="reguler-text hide-content">
                            Automate routine tasks like email management, file handling, document generation, web service integration, and more.
                        </div>
                    </div>
                    <div className="card-main">
                        <div className="card-Img">
                            <img className='img-fluid' src={card8} alt='card-img-8' />
                        </div>
                        <div className="card-head-text">
                            <h5>In-Depth
                                Analylitics</h5>
                        </div>
                        <div className="reguler-text">
                            Generate insightful business analytics with our comprehensive charts and reports.
                        </div>
                    </div>
                    <div className="card-main">
                        <div className="card-Img">
                            <img className='img-fluid' src={card9} alt='card-img-9' />
                        </div>
                        <div className="card-head-text">
                            <h5>End to End Transformation
                                Manager</h5>
                        </div>
                        <div className="reguler-text">
                            Capture requirements, estimate and assess opportunities, create prototypes, and measure progress.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default index;
