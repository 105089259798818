import React from 'react';
import Capabilities from "../Components/Home/Capabilities/index";
import Benifits from "../Components/Home/Benifits/index";
import Difference from "../Components/Home/Difference/index";
import NavbarNav from '../Components/Header/NavbarNav';
import Banner from '../Components/Home/Hero/Banner';
import HomeContact from '../Components/Home/Contact/HomeContact';
const Homepage = () => {
    return (
        <>
            <NavbarNav HomePage={true} />
            <Banner />
            <Difference />
            <Benifits />
            <Capabilities />
            <HomeContact />
        </>
    );
};

export default Homepage;
