import React, { useEffect, useState } from "react";
import "./header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Actionabl from "../../images/Actionabl.svg";
import Livello1 from "../../images/dark-logo.svg";
import { Link, useLocation } from "react-router-dom";
import ReusedButton from "../Button/ReusedButton";
import { Modal } from "react-bootstrap";
import msg from "../../images/msg-icon.svg";
import calender from "../../images/calender-icon.svg";

export const NavbarNav = ({ HomePage }) => {
    const [isActive, setIsActive] = useState(false);

    const location = useLocation();
    const isHomePage = location.pathname === "/";

    const handleButtonClick = () => {
        setIsActive(!isActive);
        document.body.classList.toggle('overflow-hidden');
    };

    const handleLinkClick = () => {
        // Remove the class from the body when a link is clicked
        document.body.classList.remove('overflow-hidden');
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        (function (h, b, s, n, i, p, e, t) {
            h._HB_ = h._HB_ || {};
            h._HB_.pid = i;
            t = b.createElement(s);
            t.type = "text/javascript";
            t.async = !0;
            t.src = n;
            e = b.getElementsByTagName(s)[0];
            e.parentNode.insertBefore(t, e);
        })(
            window,
            document,
            "script",
            "https://widget.honeybook.com/assets_users_production/websiteplacements/placement-controller.min.js",
            "65c7b491aa0292000841264b"
        );
    }, []); // The empty dependency array ensures the effect runs only once after mount

    return (
        <>
            <Navbar
                className={`nav-wrapper ${HomePage ? "homeHeader" : ""}`}
                expand="md"
            >
                <div className="container">
                    <Navbar.Brand className="Logo-wrapper">
                        <Link to="/">
                            <img
                                src={isHomePage ? Actionabl : Livello1}
                                alt="navlogo"
                                className="img-fluid"
                            />
                        </Link>
                    </Navbar.Brand>

                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav text-white"
                        onClick={handleButtonClick}
                        className={` ${isActive ? "open" : ""}`}
                    >
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>

                    <Navbar.Collapse
                        className="collapse navbar-collapse"
                        id="basic-navbar-nav"
                    >
                        <Nav className="Nav mx-auto">
                            <p className="mb-0 nav-item">
                                <Link
                                    to="/"
                                    onClick={handleLinkClick}
                                    className={`${location.pathname === "/"
                                        ? "active nav-item-text-w"
                                        : "nav-item-text-B"
                                        }`}
                                >
                                    Home
                                </Link>
                            </p>
                            <p className="mb-0 nav-item">
                                <Link
                                    to="#"
                                    onClick={handleLinkClick}
                                    className={`${location.pathname === "/about"
                                        ? "active nav-item-text-w"
                                        : "nav-item-text-B"
                                        }`}
                                >
                                    About
                                </Link>
                            </p>
                            <p className="mb-0 nav-item">
                                <Link
                                    to="/services"
                                    onClick={handleLinkClick}
                                    className={`${location.pathname === "/services"
                                        ? "active nav-item-text-w"
                                        : "nav-item-text-B"
                                        }`}
                                >
                                    Services
                                </Link>
                            </p>
                            <p className="mb-0 nav-item">
                                <Link
                                    to="/"
                                    onClick={handleLinkClick}
                                    className={`${location.pathname === "/blog"
                                        ? "active nav-item-text-w"
                                        : "nav-item-text-B"
                                        }`}
                                >
                                    Blog
                                </Link>
                            </p>
                        </Nav>

                        <div className="Nav-btn" onClick={handleShow}>
                            <ReusedButton
                                text={"Get in Touch"}
                                className={`btn ${isHomePage ? "N-btn" : "N-btn-b"}`}
                            />
                        </div>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="modal-icons">
                        <ul>
                            <li>
                                <img src={msg} alt="msg" />
                            </li>
                            <li>
                                <img src={calender} alt="calender" />
                            </li>{" "}
                        </ul>
                    </div>
                    <div className="title-h2 text-center">
                        <h2>
                            <span>Let’s </span> Talk
                        </h2>{" "}
                    </div>
                    <div className="contact-from"></div>

                    <div className="hb-p-65c7b491aa0292000841264b-1"></div>
                    <img
                        height="1"
                        width="1"
                        src="https://www.honeybook.com/p.png?pid=65c7b491aa0292000841264b"
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default NavbarNav;
