import React from 'react';
import './services.css';
import { service } from '../../ConstantData/aboutData';

const ServicesBanner = () => {
    return (
        <>
            <div className='services-wrp'>
                <div className='container'>
                    <div className='services-main'>
                        <div className='services-title text-center'>
                            <div className="about-title">Transform Your Business with <br /> Result-Oriented <span>Automation Services</span></div>
                            <p>We provide end-to-end automation products and services to drive your business forward, whether you're starting from scratch or seeking to enhance your current automated processes. Our services are designed to deliver tangible results and help you achieve your business objectives.</p>
                        </div>
                        <div className='services-section'>
                            {service.map((ser, index) => (
                                <div key={ser.firstName} className="about-item">
                                    <div className="services-content">
                                        <div className="about-title">
                                            <span>{ser.firstName}</span> {ser.lastName}
                                        </div>
                                        <div className="about-text">
                                            <p>{ser.text}</p>
                                        </div>
                                    </div>
                                    <div className="service-img">
                                        <img src={ser.img} alt={ser.name} className="img-fluid" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServicesBanner;