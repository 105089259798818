import React from 'react';
import './Benifits.css';

const index = () => {
    return (
        <div className='services-wrapper'>
            <div className="container">
                <div className="title-h2">
                    <h1 className="text-white"><span>Key</span> Benefits</h1>
                </div>
                <div className="accordion-box">
                    <div className="accordion" id="accordionExample">
                        {/* item -1 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button reguler-text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Improved Efficiency
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body accordion-body-text">
                                    With Actionabl's user intervention and error recovery systems and its central control and monitoring, you can achieve high-level efficiency, business continuity, and increased automatability.
                                </div>
                            </div>
                        </div>
                        {/* item -2 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed reguler-text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Reduced Costs
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body accordion-body-text">
                                    Our no-code and low-code modules significantly reduce development effort, saving valuable time and money and reducing 3rd party license costs.
                                </div>
                            </div>
                        </div>
                        {/* item -3 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed reguler-text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Enhanced Agility
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body accordion-body-text">
                                    Quickly adapt to changing business needs with Actionabl's externalized decision-structures and business rules. Stay competitive by quickly incorporating new technologies into your processes.
                                </div>
                            </div>
                        </div>
                        {/* item -4 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed reguler-text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Risk Management & Resilience
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body accordion-body-text">
                                    With audit and QC checks, comprehensive error recovery, and shared human-machine workspaces, Actionabl ensures business continuity and robust risk management.
                                </div>
                            </div>
                        </div>
                        {/* item -5 */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed reguler-text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Open Architecture
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div className="accordion-body accordion-body-text">
                                    Swiftly and easily integrate 3rd party tools and open-source frameworks with Actionabl's open architecture.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="RectBlur-img">
                {/* <img src={RectBlur} alt='RectBlur' className="img-fluid" /> */}
            </div>
            <div className="RectPink-img">
                {/* <img src={RectPink} alt='RectPink' className="img-fluid" /> */}
            </div>
            <div className="RectOrange-img">
                {/* <img src={RectOrange} alt='RectOrange' className="img-fluid" /> */}
            </div>
        </div>
    );
};

export default index;
